import Modal from "react-bootstrap/Modal";
import React from "react";

import "./HomeModal.css";
import Slider from "react-slick";
import { HomeModalContent } from ".";
import modalVideo from "../../image/home_banner/HomeVideo.mp4";
import modalImage from "../../image/home_banner/popup.gif";


const HomeModal = ({ show, onHide }) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            className="home-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ backdropFilter: "blur(0px)", zIndex: "9999999",   background: "rgba(2, 2, 2, 0.66)" }}
        >
            <Modal.Header closeButton></Modal.Header>
            <a className="homepoup" href="/skill-vocational">
            <img src={modalImage} />
            
            </a>
        </Modal>
    );
};

export default HomeModal;
