import React, { useState } from "react";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Landing from "../Landing/Landing";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import SideBar from "../SideBar/SideBar";
import "./Content.css";
import "./Achievement.css"
import achTopIcon from "../../image/newImg/ach.png";
import award_1 from "../../image/award/award_1.jpg";
import award_2 from "../../image/award/award_2.jpg";
import award_3 from "../../image/award/award_3.jpg";
import award_4 from "../../image/award/award_4.jpg";
import award_5 from "../../image/award/award_5.jpg";
import award_6 from "../../image/award/award_6.jpg";
import award7 from "../../image/award/Achievement.jpg";
import award8 from "../../image/award/Career 360 award.jpg";
import award9 from "../../image/award/Education Today Award.jpg";
import award10 from "../../image/award/education world award.jpg";
import achievmentsimg from "../../image/award/Achievement.jpg";
import { Col, Row } from "react-bootstrap";

function Achievements(props) {
  const { cover, sidebar_data, side_heading, side_sub_heading } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, setIsShow] = useState(false);

  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    } else if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };

  const handleClick = () => {
    setIsShow(!isShow);
  };

  return (
    <div className="content_main" onScroll={handleScroll}>
      <Navbar isScrolled={isScrolled} handleClick={handleClick} />
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <Landing video={false} cover={cover} />
      <div className="contentmain_cont">
        <div className="sidebar_container">
          <SideBar
            sidebar_data={sidebar_data}
            side_heading={side_heading}
            side_sub_heading={side_sub_heading}
          />
          <Contact />
        </div>
        <div className="main_content">
          <div className="header">
            <img className="admissions_image" src={achTopIcon} alt="Achievements Icon" />
            <h1>Achievements & Laurels</h1>
          </div>
          <div className="intro">
            <p className="intro_text">
              "The harder you work for something, the greater you'll feel when you achieve it." It is a jubilant & ecstatic moment for Herman Gmeiner School to announce that our school has been nominated for the Global Awards for its astounding innovation in Pedagogy.
            </p>
            <div className="achievement_section">
              
              <div className="achievement_content">
                <div className="achievement_text">
                <h2 className="section_title">School’s Achievements</h2>
                  Take pride in how far we have come and have faith in how far we can go. Congratulations are in order!! A proud moment for Hermann Gmeiner School as the school has once again been awarded, and this time RANKED No. 1 IN NAINITAL With a Certificate of Achievement in "INDIA’S TOP DAY CUM BOARDING SCHOOL”, by Education Today. This award brings honor and opens the door to more success in the future. This is a much-deserved award for a school that believes in consistent hard work and pursues excellence. It is inspirational to witness hard work giving birth to success. The school has been recognized with such a prestigious award through the combined efforts of the teachers and students. May we keep climbing new heights of success!
                </div>
                <div className="achievement_image">
                  <img src={award7} alt="Award" />
                </div>
              </div>
            </div>
            <div className="award_gallery">
              <h2 className="section_title">Awards Gallery</h2>
              <Row>
                {[award_1, award_2, award8, award_4].map((award, index) => (
                  <Col key={index} className="award_col">
                    <img className="awardimg" src={award} alt={`Award ${index + 1}`} />
                  </Col>
                ))}
              </Row>
              <Row>
                {[award_5, award_3, award_6,award9,award10].map((award, index) => (
                  <Col key={index} className="award_col">
                    <img className="awardimg" src={award} alt={`Award ${index + 1}`} />
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Achievements;

