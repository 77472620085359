import React, { useState } from "react";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Landing from "../Landing/Landing";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import SideBar from "../SideBar/SideBar";
import fsTopIcon from "../../image/newImg/fs.png";
import "./Content.css";
function Faculty(props) {
  const {
    cover,
    sidebar_data,
    data,
    title,
    alumni_data,
    curriculum_data,
    side_heading,
    side_sub_heading,
  } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);
  const handleScroll = (e) => {
    console.log("asd");
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };
  const handleClick = () => {
    steIsShow(!isShow);
    console.log(isShow);
  };

  return (
    <div className="content_main" onScroll={handleScroll}>
      <Navbar isScrolled={isScrolled} handleClick={handleClick} />
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <Landing video={false} cover={cover} />
      <div className="contentmain_cont">
        <div style={{ width: "min-content" }}>
          <SideBar
            sidebar_data={sidebar_data}
            side_heading={side_heading}
            side_sub_heading={side_sub_heading}
          />
          <Contact />
        </div>
        <div>
          <div className={`f-2 f-500 mb-3`} style={{ color: "#0076BD" }}>
            <img
              src={fsTopIcon}
              style={{ width: "4rem", marginRight: "1rem" }}
            />
            Faculty & Staff
          </div>
          <div>
            <div className="d-flex gap-2 mt-1">
              <div>
                {/* <div className="f-bold f-1-2 mb-2"></div> */}
                <div className="color-grey f-500">
                  Hermann Gmeiner School Bhimtal has a very experienced,
                  knowledgeable, well-qualified and dedicated Staff, so much so
                  that they are conscious of their responsibility of being the
                  most important block in the foundation of the Nation as well
                  as the Society.
                </div>
                <div className="color-grey f-500 mt-2">
                  Their self-discipline is an example in itself, which always
                  drives them to work as a well-knit team to make all possible
                  endeavour together for the holistic growth of students as well
                  the institution. They are enthusiastic, dedicated,
                  conscientious and highly motivated to adapt to new innovative
                  ideas and well versed with modern education systems.
                </div>
                <div className="color-grey f-500 mt-2">
                  The valuable contribution of all the Faculty members is well
                  manifested in the spectacular achievements of our students in
                  the scholastic as well as co- scholastic discipline.
                </div>
                <div className="color-grey f-500 mt-2">
                  The Teachers grouped under various subject-wise Faculties are
                  put under the guidance of the most experienced Faculty heads.
                  All of them always put their heads together to devise
                  improvised methodology to deliver their best to facilitate the
                  young evolving minds. This significantly contributes in
                  promoting the problem solving skills of students to enable
                  them to grow as useful citizens.
                </div>
              </div>
            </div>
          </div>
          <div className="faculty_staff">
            <div className="gap-2 mt-3">
              <div>
                <div className="text-center f-bold f-1-2 mb-4 ">
                  HERMANN GMEINER SCHOOL, BHIMTAL (NAINI TAL) UK <br />
                  DETAILS OF TEACHING STAFF (2024-25)
                </div>
                <div className="text-center">
                  <table className="table table-striped table-bordered">
                    <thead style={{
                      backgroundColor: '#0076bd',
                      color: 'white'
                    }}>
                      <tr>
                        <th scope="col">Sr.</th>
                        <th scope="col">Teacher's Name</th>
                        <th scope="col">Qualifications</th>
                        <th scope="col">Designation</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr><th scope='row'>1</th><td>MRS. MAHIMA BHATT</td><td>M.Sc, M.A. B.Ed.</td><td>Principal</td></tr>
                      <tr><th scope='row'>2</th><td>MS. ANUSUYA SAH</td><td>M.COM, </td><td>HM/PGT B STUDIES</td></tr>
                      <tr><th scope='row'>3</th><td>Mr. KAILASH CHANDRA GUNWANT </td><td>M.Sc.(Maths) B.Ed.</td><td>PGT(Maths)</td></tr>
                      <tr><th scope='row'>4</th><td>Mrs. ANANTIKA PANDEY</td><td>M.A.(English) B.Ed.</td><td>PGT(English)</td></tr>
                      <tr><th scope='row'>5</th><td>Dr. YOGITA VERMA</td><td>M.A.(Fine Art) M.Ed. Ph.D</td><td>PGT(Painting)</td></tr>
                      <tr><th scope='row'>6</th><td>MRS. JAYA NEGI</td><td>M.A.(Hindi) B.Ed.,UTET -II</td><td>PGT(Hindi)</td></tr>
                      <tr><th scope='row'>7</th><td>MR. UMESH CHANDRA JOSHI</td><td>M.Sc. (Maths) B.Ed.</td><td>PGT(Maths)</td></tr>
                      <tr><th scope='row'>8</th><td>MR. KAMLESH SINGH KANYAL</td><td>M.Sc. (Physics), B.Ed.</td><td>PGT (Physics)</td></tr>
                      <tr><th scope='row'>9</th><td>MISS LALITA JOSHI</td><td>M.Sc.(Comp Sc.) PGDSLM</td><td>PGT(Comp.Sc.)</td></tr>
                      <tr><th scope='row'>10</th><td>MR. RAM KRISHAN SHARMA</td><td>M.Com, B.Ed.</td><td>PGT (Accountancy)</td></tr>
                      <tr><th scope='row'>11</th><td>MR. KAMAL KISHOR PATHAK</td><td>M.Sc. (Zoology) B.Ed.</td><td>PGT (Bio.)</td></tr>
                      <tr><th scope='row'>12</th><td>MR. ALFONSO</td><td>M.A. (English), B.Ed.</td><td>PGT (Eng)</td></tr>
                      <tr><th scope='row'>13</th><td>MR. SUDHIR KUNDU</td><td>M.P.Ed.</td><td>PGT (Phy.Edu)</td></tr>
                      <tr><th scope='row'>14</th><td>MR. HIMANSU FARTIYAL</td><td>B.Com., M.P.Ed.</td><td>PGT (Phy. Edu.)</td></tr>
                      <tr><th scope='row'>15</th><td>MR. DEEPAK TIWARI</td><td>M.A.B.ED.</td><td>PGT (Geog.)</td></tr>
                      <tr><th scope='row'>16</th><td>MR. BIPIN CHANDRA PALARIYA</td><td>MA.Eco., B.Ed</td><td>PGT (Economics)</td></tr>
                      <tr><th scope='row'>17</th><td>MR. ASHUTOSH PAL</td><td>M.Sc., B.Ed.</td><td>PGT (Chemistry)</td></tr>
                      <tr><th scope='row'>18</th><td>Mrs. MEENAKSHI BISHT</td><td>M.A. (History) B.Ed.</td><td>TGT(S.Sc.)</td></tr>
                      <tr><th scope='row'>19</th><td>Ms. MEENA BHAN</td><td>M.Sc.(Maths) B.ED.</td><td>TGT(Maths)</td></tr>
                      <tr><th scope='row'>20</th><td>Mrs. INDU BHAKT</td><td>M.A.(Pol Sc.) BED.,VISHARAD</td><td>TGT(Music)</td></tr>
                      <tr><th scope='row'>21</th><td>Mr. ARVIND KUMAR</td><td>MCA,MA Eng, B.Ed, M.Sc . </td><td>TGT(Comp)</td></tr>
                      <tr><th scope='row'>22</th><td>MS. RICHA MAMGAIN</td><td>M.A. (English), PRABHAKAR</td><td>TGT Music</td></tr>
                      <tr><th scope='row'>23</th><td>MR. BIPIN CHANDRA UPRETI</td><td>M.Sc.(Zoology), B.Ed.</td><td>TGT(Science)</td></tr>
                      <tr><th scope='row'>24</th><td>MR. AMIT KUMAR JOSHI</td><td>M.A. (Hindi)/Sans, B.Ed. UTET</td><td>TGT(Hindi)</td></tr>
                      <tr><th scope='row'>25</th><td>MS. SEEMA GERA</td><td>M.A.(Sociology), B.Ed.</td><td>TGT(S.St.)</td></tr>
                      <tr><th scope='row'>26</th><td>MRS TARA BISHT</td><td>M.Sc . (Chem.) B.Ed. CTET</td><td>TGT-SCI</td></tr>
                      <tr><th scope='row'>27</th><td>MR. ANAND SINGH</td><td>M.A.(English) B.Ed.</td><td>TGT (English)</td></tr>
                      <tr><th scope='row'>28</th><td>MR. RAJESH K. TIWARI</td><td>B.Sc.,M.A.(English) B.Ed.</td><td>TGT (SCI)</td></tr>
                      <tr><th scope='row'>29</th><td>MR. MUKESH CHANDRA BELWAL</td><td>M.A. (Hindi & Sansk),B.Ed.</td><td>TGT (HINDI)</td></tr>
                      <tr><th scope='row'>30</th><td>MS. SUNITA RANI ARORA</td><td>M.A.(Hindi/Sans) B Ed.</td><td>TGT (HINDI)</td></tr>
                      <tr><th scope='row'>31</th><td>MR.DEEP CHANDRA JOSHI</td><td>M.Sc. (Maths),B.Ed.</td><td>TGT Maths</td></tr>
                      <tr><th scope='row'>32</th><td>MR. ASHISH KAUL</td><td>B.P.E.</td><td>WELLNESS TEACHER</td></tr>
                      <tr><th scope='row'>33</th><td>MRS. SUMAN RAWAT</td><td>M.A. (Hist & Socio.) B.ED. D.L.Ed.</td><td>SPECIAL EDUCATIOR</td></tr>
                      <tr><th scope='row'>34</th><td>MRS. POONAM PANDEY</td><td>B.A. NTT, B.Ed.D.L.Ed</td><td>KG TEACHER</td></tr>
                      <tr><th scope='row'>35</th><td>MRS. LEENA BISHT</td><td>B.A. NTT, BED, D.L.ED, CTET</td><td>KG TEACHER</td></tr>
                      <tr><th scope='row'>36</th><td>MRS. KIRAN PATWAL</td><td>M.A. (Economics) B.Ed. D.LED.CTET, UTET</td><td>PRT</td></tr>
                      <tr><th scope='row'>37</th><td>MRS MEENAKSHI SINGH</td><td>M.A.(English) THC</td><td>PRT</td></tr>
                      <tr><th scope='row'>38</th><td>MRS. JYOTI OJHA</td><td>M.A.(Eco.& Hindi) B.Ed.</td><td>PRT</td></tr>
                      <tr><th scope='row'>39</th><td>MISS KALPANA RAWAT</td><td>M.A. (Music), Visharad, D.L.Ed.</td><td>PRT</td></tr>
                      <tr><th scope='row'>40</th><td>MRS. BHAWNA SHARMA</td><td>B.Com.,B.ED.</td><td>PRT</td></tr>
                      <tr><th scope='row'>41</th><td>MRS. SMITA SRIVASTAVA</td><td>M.A. B.Ed. DLED</td><td>PRT</td></tr>
                      <tr><th scope='row'>42</th><td>MR. BHAGWAT SINGH</td><td>PG PHY EDUCATION</td><td>PRT PET</td></tr>
                      <tr><th scope='row'>43</th><td>MS. SANGITA MATTOO</td><td>B.A., BED.</td><td>PRT</td></tr>
                      <tr><th scope='row'>44</th><td>MS PRIYA GIRI</td><td>M.Sc. (ZOOLOGY), B.Ed.</td><td>PRT</td></tr>
                      <tr><th scope='row'>45</th><td>MS. TARA JOSHI</td><td>M.A. (ECO),B.Ed</td><td>PRT</td></tr>
                      <tr><th scope='row'>46</th><td>MS. ANJALI UPADHYAY</td><td>B.Sc, B.Ed. CTET</td><td>PRT</td></tr>
                      <tr><th scope='row'>47</th><td>MS JANKI CHAUNAL</td><td>B.A. NTT</td><td>ASSTT TEACHER</td></tr>
                      <tr><th scope='row'>48</th><td>MR. VINOD CHANDRA JOSHI</td><td>M.A, CERTIFICATE IN LIB Sc,, B.LIB</td><td>LIBRARIAN</td></tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Faculty;
