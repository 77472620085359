import React from "react";
import { Col, Row } from "react-bootstrap";
import "./Boarding.css";
import boarding_plant from "../../image/boarding/Bhimtal.jpg";
import sports from "../../image/boarding/sports.webp";

function Boardings(props) {
  const { data } = props;
  return (
    <> 
   <div className="container mt-5">
  <header className="text-center mb-4">
    <h1>
      Building Character and Skills for Life <br /> Welcome to Our Boarding House
    </h1>
  </header>
  <section>
    <p>
      At Hermann Gmeiner School Bhimtal, our Boarding House offers an enriching
      environment where students not only thrive academically but also grow
      physically, socially, and emotionally. Our goal is to ensure that every
      student is equipped with the right life skills, values, and experiences to
      succeed in the future.
    </p>
    <img src={boarding_plant} className="building" alt="boarding" />
  </section>
  <section className="mt-5">  
    <h2>Physical Development: Health, Fitness &amp; Recreation</h2>
    <p>
      We understand the importance of balancing academics with physical
      development. In our Boarding House, students have access to a variety of
      physical activities that promote overall health and wellness:
    </p>
    <ul>
      <li>
        <strong>Morning Activities:</strong> Each day begins with a refreshing
        morning activity that energizes students and prepares them for the day
        ahead.
      </li>
      <li>
        <strong>Yoga and Aerobics:</strong> We offer yoga and aerobics sessions
        to help students improve their flexibility, mental clarity, and physical
        health.
      </li>
      <li>
        <strong>Weekly Competitive Sessions:</strong> Regular competitive sports
        and games keep students active, encouraging teamwork, discipline, and
        leadership.
      </li>
      <li>
        <strong>Recreational Movie Time:</strong> We believe in giving students
        time to relax and bond with peers, and movie time is a weekly
        recreational activity that fosters relaxation and socialization.
      </li>
      <li>
        <strong>Local Outings:</strong> Every weekend, students get the chance
        to explore the local area through weekend outings, enriching their
        cultural experiences and offering a break from routine.
      </li>
      <li>
        <strong>Campus Cleaning &amp; Dignity at Work:</strong> We emphasize the
        importance of dignity at work through regular campus cleaning sessions,
        helping students develop responsibility, teamwork, and respect for their
        surroundings.
      </li>
    </ul>
    <p><img src={sports} className="sports" alt="" /></p>
  </section>
  <section className="mt-5">
    <h2>Academics: Holistic Learning &amp; Development</h2>
    <p>
      At Hermann Gmeiner School Bhimtal, academic success is as important as
      personal growth. We provide a nurturing environment that promotes
      learning, discipline, and self-motivation:
    </p>
    <ul>
      <li>
        <strong>Smart Panels &amp; Extra Support Classes:</strong> To enhance
        learning, students have access to smart panels (75 - inch screens) in
        classrooms, making lessons more interactive. For those who need
        additional help, extra support classes are available based on individual
        student needs.
      </li>
      <li>
        <strong>Robotics and ATL Lab:</strong> We offer hands-on learning
        opportunities in our Robotics and ATL (Atal Tinkering Lab), giving
        students the chance to explore STEM fields through practical
        applications.
      </li>
    </ul>
  </section>
  <section className="mt-5">
    <h2>Well-Being: Nutrition &amp; Health Support and Surveillance</h2>
    <p>
      We prioritize the health and well-being of all our students by providing
      nutritious meals and medical support:
    </p>
    <ul>
      <li>
        <strong>Nutritious Meals:</strong> Our Boarding House offers a 5-course
        meal every day, including breakfast, lunch, tea time with snacks, and
        dinner, ensuring that students receive a balanced and healthy diet.
      </li>
      <li>
        <strong>Medical Facilities &amp; Trained Staff:</strong> We provide
        medical facilities with trained staff to attend to the health needs of
        students, ensuring peace of mind for both parents and students.
      </li>
      <li>
        <strong>CCTV Security:</strong> The safety of our students is paramount,
        and we have installed CCTV cameras to monitor the premises, ensuring a
        secure environment.
      </li>
    </ul>
  </section>
  <section className="mt-5">
    <h2>Value for Money: Affordable Excellence</h2>
    <p>
      At Hermann Gmeiner School Bhimtal, we provide excellent facilities and
      services that offer great value for money:
    </p>
    <ul>
      <li>
        <strong>Smart Panels &amp; Robotics Access:</strong> Our access to smart
        panels and cutting-edge Robotics and ATL labs ensures that students
        receive high-quality educational tools and opportunities, making the
        most of their learning experience.
      </li>
      <li>
        <strong>NCC (National Cadet Corps):</strong> We offer special preference
        for students interested in joining the NCC, giving them the opportunity
        to develop leadership, discipline, and teamwork skills.
      </li>
    </ul>
  </section>
</div>

    </> 
  );
}

export default Boardings;
